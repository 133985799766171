import dayjs from "dayjs";

/**ag-grid cell valueFormatter 
   params : string형식 날짜 value
   return : yyyy-mm-dd 형식으로 변환하여 리턴
*/
export const dateFormatter = (params) => {
 
  let dateformat = dayjs(params.value).format("YYYY-MM-DD");
  if (dateformat === "Invalid Date") {
    return "";
  } else {
    return dateformat;
  }
};
/**ag-grid cell currencyFormatter 
   params : string형식 금액 value
   return : 123,456,789 형식으로 변환하여 리턴
*/
export const currencyFormatter = (params) => {
  let number = params.value;
  if (
    number === "" ||
    number === null ||
    typeof number === "undefined"
  ) {
    return params.value;
  }else{
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
  }
};

/**ag-grid cell colSpan 
  fieldData  : string형식 field.
  fieldValue : field기준 값.
  mergeCount : 5 (숫자 형식으로 변환하여 리턴)
*/
export const colSpan = (fieldData,fieldValue,mergeCount) => {  
  if (fieldData === fieldValue) {    
    return mergeCount;        
  } else {
    return 1;
  }           
}

/**ag-grid cell colSpan 
  fieldData  : string형식 field.
  fieldValue : field기준 값.
  rowIndex   : rowIndex (화면에서 params.node.rowIndex+ 1 작성)
*/
export const valueGetter =(fieldData,fieldValue,rowIndex)=>{
  if (fieldData === fieldValue) {  
    return '합 계';        
  }else if (fieldData ==='average'){
    return '평 균';
  } else {
    return rowIndex;
    // return '평균';
  }   
}
//
/**ag-grid cell rowSpan 
  fieldData  : string형식 field.
  fieldValue : field기준 값.
  mergeCount : 5 (숫자 형식으로 변환하여 리턴)
*/
export const rowSpan = (fieldData,fieldValue,mergeCount) => {  
  if (fieldData === fieldValue) {    
    return mergeCount;        
  } else {
    return 1;
  }           
}

export const truncateText  = (params) => {  
  const value = params.value || '';
  return (
    <div style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      padding:'1px',
    }} title={value}>
      {value}
    </div>
  );       
}